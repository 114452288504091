import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import rutas from '@/router/_config'
Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'hash', // https://router.vuejs.org/api/#mode
  linkActiveClass: 'open active',
  scrollBehavior: () => ({ y: 0 }),
  routes: configRoutes(),
})

function configRoutes() {
  return rutas.listado
}

router.beforeEach((to, from, next) => {
  // Check login
  if (to.path === '/login' && store.getters.authStatus) {
    next({ name: 'Inicio' })
    return
  }
  // Check Auth
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.getters.authStatus) {
      // Check Director
      if (to.matched.some((record) => record.meta.is_director)) {
        if (store.getters.getRole > 1) {
          next()
          return
        } else {
          next({ name: 'Inicio' })
          return
        }
      }
      // Check Tesorero
      if (to.matched.some((record) => record.meta.is_tesorero)) {
        if (store.getters.getRole > 2) {
          next()
          return
        } else {
          next({ name: 'Inicio' })
          return
        }
      }
      // Check Admin
      if (to.matched.some((record) => record.meta.is_admin)) {
        if (store.getters.getRole > 3) {
          next()
          return
        } else {
          next({ name: 'Inicio' })
          return
        }
      }
      // Check Super
      if (to.matched.some((record) => record.meta.is_super)) {
        if (store.getters.getRole > 4) {
          next()
          return
        } else {
          next({ name: 'Inicio' })
          return
        }
      }
    } else {
      next({ name: 'login' })
      return
    }
  }
  next()
})
export default router
/*
router.beforeEach((to, from, next) => {
  if(to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters.authStatus) {
      if(to.matched.some(record => record.meta.is_super)) {
        //provisorio
        if (store.getters.getRole == 1 || store.getters.getRole == 3) {
          next()
          return
        }
        else{
          next({ name: 'Inicio' })
          return

        }
      }
      next()
      return
    }
    next({ name: 'login' })
  }
  else {
    next()
    return
  }
  if(to.path === '/login' && store.getters.authStatus) {
        next({ name: 'Inicio' })
        return
  }
})
export default router
*/
