export default {
  listado: [
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Auth/Login'),
    },
    {
      path: '/',
      redirect: { name: 'Inicio' },
      name: 'Principal',
      component: () => import('@/layouts/Container'),
      meta: { requiresAuth: true },
      children: [
        {
          path: 'dashboard',
          name: 'Inicio',
          component: () => import('@/views/Dashboard/Index'),
        },
        {
          path: 'tickets',
          meta: { label: 'Tickets' },
          component: {
            render(c) {
              return c('router-view')
            },
          },
          children: [
            {
              name: 'Listado de Tickets',
              path: '',
              component: () => import('@/views/Tickets/TicketsTable'),
            },
            {
              path: 'create',
              name: 'Nuevo Tickets',
              component: () => import('@/views/Tickets/TicketsCreate'),
            },
          ],
        },
        {
          path: 'lotes',
          redirect: '/lotes',
          component: {
            render(c) {
              return c('router-view')
            },
          },
          children: [
            /* {  meta: { label: 'Lotes', is_director: true}, name: 'Lotes Lista', path: 'director', component: LotesTableDirector} */
            {
              meta: { label: 'Lotes', is_director: true },
              name: 'Lista de Lotes',
              path: '',
              component: () => import('@/views/Lotes/LotesTable'),
            },
            {
              meta: { label: 'Lotes', is_tesorero: true },
              name: 'Liquidar Lote',
              path: 'liquidar/:id',
              component: () => import('@/views/Lotes/LotesLiquidar'),
            },
          ],
        },
        {
          path: 'ticketspendientes',
          meta: { label: 'Gestion', is_director: true },
          redirect: '/ticketspendientes',
          component: {
            render(c) {
              return c('router-view')
            },
          },
          children: [
            {
              name: 'Tickets Pendientes',
              path: '',
              component: () =>
                import('@/views/GestionTickets/TicketsDireccionAutorizar'),
            },
          ],
        },
        {
          path: 'vincularTickets',
          meta: { label: 'Gestion', is_director: true },
          name: 'Vincular Ticket',
          component: () =>
            import('@/views/GestionTickets/TicketsDireccionVincular'),
        },
        {
          path: 'visortickets',
          name: 'Todos los Tickets',
          component: () => import('@/views/VisorTickets/VisorTicketsTable'),
        },
        {
          path: 'gestion',
          meta: { label: 'Gestion', is_admin: true },
          redirect: '/gestion/clientes',
          component: {
            render(c) {
              return c('router-view')
            },
          },
          children: [
            {
              path: 'localidades',
              meta: { label: 'Localidades' },
              component: {
                render(c) {
                  return c('router-view')
                },
              },
              children: [
                {
                  name: 'Lista Localidades',
                  path: '',
                  component: () =>
                    import('@/views/Localidades/LocalidadesTable'),
                },
                {
                  path: 'create',
                  name: 'Nuevo Localidad',
                  component: () =>
                    import('@/views/Localidades/LocalidadesCreate'),
                },
                {
                  path: 'edit/:id',
                  name: 'Editar Localidad',
                  component: () =>
                    import('@/views/Localidades/LocalidadesEdit'),
                },
              ],
            },
            {
              path: 'barrios',
              meta: { label: 'Barrios' },
              component: {
                render(c) {
                  return c('router-view')
                },
              },
              children: [
                {
                  name: 'Lista de Barrios',
                  path: '',
                  component: () => import('@/views/Barrios/BarriosTable'),
                },
                {
                  path: 'create',
                  name: 'Nuevo Barrio',
                  component: () => import('@/views/Barrios/BarriosCreate'),
                },
                {
                  path: 'edit/:id',
                  name: 'Editar Barrio',
                  component: () => import('@/views/Barrios/BarriosEdit'),
                },
              ],
            },
            {
              path: 'clientes',
              meta: { label: 'Clientes' },
              component: {
                render(c) {
                  return c('router-view')
                },
              },
              children: [
                {
                  name: 'Listado de Personas',
                  path: '',
                  component: () => import('@/views/Clientes/ClientesTable'),
                },
                {
                  path: 'create',
                  name: 'Alta de Personas',
                  component: () => import('@/views/Clientes/ClientesCreate'),
                },
                {
                  path: 'edit/:id',
                  name: 'Editar Personas',
                  component: () => import('@/views/Clientes/ClientesEdit'),
                },
              ],
            },
          ],
        },
        {
          path: 'sistema',
          meta: { label: 'Sistema', is_super: true },
          redirect: '/sistema/usuarios',
          component: {
            render(c) {
              return c('router-view')
            },
          },
          children: [
            {
              path: 'padron',
              meta: { label: 'Padron', is_super: true },
              component: {
                render(c) {
                  return c('router-view')
                },
              },
              children: [
                {
                  name: 'Lista Padron',
                  path: '',
                  component: () => import('@/views/Padron/PadronTable'),
                },
                {
                  path: 'create',
                  name: 'Nuevo Padron',
                  component: () => import('@/views/Padron/PadronCreate'),
                },
                {
                  path: 'edit/:dni',
                  name: 'Editar Padron',
                  component: () => import('@/views/Padron/PadronEdit'),
                },
              ],
            },
            {
              path: 'usuarios',
              meta: { label: 'Usuarios' },
              component: {
                render(c) {
                  return c('router-view')
                },
              },
              children: [
                {
                  name: 'Listado de Usuarios',
                  path: '',
                  component: () => import('@/views/Usuarios/UsuariosTable'),
                },
                {
                  path: 'create',
                  name: 'Nuevo Usuario',
                  component: () => import('@/views/Usuarios/UsuariosCreate'),
                },
                {
                  path: 'edit/:id',
                  name: 'Editar Usuario',
                  component: () => import('@/views/Usuarios/UsuariosEdit'),
                },
                {
                  path: 'clearpass/:id',
                  name: 'Blanquear Usuario',
                  component: () => import('@/views/Usuarios/UsuariosClearPass'),
                },
              ],
            },
            {
              path: 'prestadores',
              meta: { label: 'Prestadores' },
              component: {
                render(c) {
                  return c('router-view')
                },
              },
              children: [
                {
                  name: 'Listado de Prestadores',
                  path: '',
                  component: () =>
                    import('@/views/Prestadores/PrestadoresTable'),
                },
                {
                  path: 'create',
                  name: 'Nuevo Prestador',
                  component: () =>
                    import('@/views/Prestadores/PrestadoresCreate'),
                },
                {
                  path: 'edit/:id',
                  name: 'Editar Prestador',
                  component: () =>
                    import('@/views/Prestadores/PrestadoresEdit'),
                },
                {
                  path: 'clearpass/:id',
                  name: 'Blanquear Prestador',
                  component: () =>
                    import('@/views/Prestadores/PrestadoresClearPass'),
                },
              ],
            },
            // Vistas Areas
            {
              path: 'areas',
              meta: { label: 'Areas' },
              component: {
                render(c) {
                  return c('router-view')
                },
              },
              children: [
                {
                  name: 'Lista de Areas',
                  path: '',
                  component: () => import('@/views/Areas/AreasTable'),
                },
                {
                  path: 'create',
                  name: 'Nueva Sucursal',
                  component: () => import('@/views/Areas/AreasCreate'),
                },
                {
                  path: 'edit/:id',
                  name: 'Editar Sucursal',
                  component: () => import('@/views/Areas/AreasEdit'),
                },
              ],
            },
            {
              path: 'conceptos',
              meta: { label: 'Conceptos' },
              component: {
                render(c) {
                  return c('router-view')
                },
              },
              children: [
                {
                  name: 'Listado',
                  path: '',
                  component: () => import('@/views/Conceptos/ConceptosTable'),
                },
                {
                  path: 'create',
                  name: 'Nuevo concepto',
                  component: () => import('@/views/Conceptos/ConceptosCreate'),
                },
                {
                  path: 'edit/:id',
                  name: 'Editar concepto',
                  component: () => import('@/views/Conceptos/ConceptosEdit'),
                },
              ],
            },
          ],
        },
        {
          path: 'gestion-tickets',
          meta: { label: 'Gestion Tickets', is_super: true },
          component: {
            render(c) {
              return c('router-view')
            },
          },
          children: [
            {
              name: 'Tabla completa de Tickets',
              path: '',
              component: () =>
                import('@/views/GestionTickets/GestionTicketsTable'),
            },
            {
              path: 'create',
              name: 'Alta Tickets',
              component: () =>
                import('@/views/GestionTickets/GestionTicketsCreate'),
            },
            /* { name: 'Vincular Ticket', path: 'vincular/:code'}, */
          ],
        },
        {
          name: 'Informes',
          path: 'informes',
          meta: { label: 'Informes', is_super: true },
          component: {
            render(c) {
              return c('router-view')
            },
          },
          children: [
            {
              name: 'Informes Areas',
              path: 'areas',
              component: () => import('@/views/Informes/InformesTodasAreas'),
            },
            {
              name: 'Informes Prestadores',
              path: 'prestadores',
              component: () => import('@/views/Informes/InformesPrestadores'),
            },
            {
              name: 'Informes Conceptos Area',
              path: 'conceptos',
              component: () => import('@/views/Informes/InformesConceptoArea'),
            },
            {
              name: 'Informes Top',
              path: 'top',
              component: () => import('@/views/Informes/InformesTop'),
            },
          ],
        },
        {
          path: 'logs',
          name: 'Logs Sistema',
          component: () => import('@/views/Logs/LogsTable'),
        },
        // {
        //   name: 'WhatsApp Dashboard',
        //   path: 'wsdash',
        //   meta: { is_super: true },
        //   component: () => import('@/views/WhatsApp/Dashboard'),
        // },
        {
          path: 'changepass',
          name: 'Cambiar Contraseña',
          component: () => import('@/views/Auth/ChangePass'),
        },
      ],
    },
    {
      path: '*',
      component: () => import('@/views/System/PageNotFound'),
    },
    {
      path: '/activar/:idCliente/:code',
      name: 'activar',
      component: () => import('@/views/WhatsApp/Activar'),
    },
    {
      path: '/logout',
      name: 'logout',
      component: () => import('@/views/Auth/Logout'),
    },
  ],
}
